import React, { MouseEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context, filterTariffs } from "../utils";

interface Props {
  order?: any;
  rate?: TariffType;
  label?: string;
  setValue: (name: string, data: any) => void;
  labelClass: string;
  parentClass: string;
}

export default function Rates({
  rate,
  order,
  label,
  setValue,
  labelClass,
  parentClass,
}: Props) {
  const { t } = useTranslation();
  var tariffs = useContext(Context).state.settings.tariffs;
  const { unit, recipient_address, sender_address } = order;
  const [active, setActive] = useState<number | undefined>(rate?.id);
  tariffs = filterTariffs(tariffs, { recipient_address, sender_address });

  const handleActive = (
    e: MouseEvent<HTMLButtonElement>,
    tariff: TariffType
  ) => {
    e.preventDefault();
    setValue("shipment_type", tariff);
    setActive(tariff.id);
  };

  useEffect(() => {
    if (!rate) {
      setActive(tariffs[0].id);
      setValue("shipment_type", tariffs[0]);
    }
  }, []);

  return (
    <>
      {label && (
        <label htmlFor="rate" className={labelClass}>
          {label}
        </label>
      )}
      <div className={`rates gap-20 flex-wrap ${parentClass}`}>
        {tariffs.map((tariff: TariffType) => (
          <button
            className={`rate-box btn ${active === tariff.id ? "active" : ""}`}
            key={tariff.id}
            onClick={(e) => handleActive(e, tariff)}
          >
            <h6 className="size-6 natural-2 text-bold lh-32 m-0">
              {tariff.name}
            </h6>
            <div className="rate-box-body">
              <h6 className="size-8 natural-4 text-medium lh-20">
                {t("Минимум")}: <b className="natural-2">{tariff.min_price}$</b>
              </h6>
              <h6 className="size-7 natural- 2 text-bold lh-20 m-0">
                {unit === "kg/sm"
                  ? tariff.weight_rate.rate_kg
                  : tariff.weight_rate.rate}{" "}
                $ {t("за")} 1 {t(unit)}
              </h6>
            </div>
            <hr className="mb-3" />
            <h6 className="d-flex-center justify-content-center text-center size-7 primary-5 text-bold lh-20 m-0">
              {t("Узнать больше")}
            </h6>
          </button>
        ))}
      </div>
    </>
  );
}
