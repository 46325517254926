export const date = new Date();

export const emailValidation = {
  pattern: {
    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    message: "Invalid email address",
  },
};

export const phoneValidation = {
  pattern: {
    value: /^\+\d{12}$/,
    message: "Invalid phone number (example: +998939991122)",
  },
};

export const doc_types = [
  { label: "ID", value: "id" },
  { label: "Driver license", value: "driver_lic" },
  { label: "Passport", value: "passport" },
  { label: "PNFL", value: "pnfl" },
  { label: "World passport", value: "world_passport" },
  { label: "Green card", value: "green_card" },
  { label: "Work authorization", value: "work_authorization" },
  { label: "Tin", value: "tin" },
];

export const orderTypes = [
  {
    icon: "/icons/send-delivery.png",
    label: "Отправить посылку",
    value: 4,
    name: "order",
  },
  {
    icon: "/icons/cart.svg",
    label: "Интернет заказ",
    value: 2,
    name: "tracking",
  },
  {
    icon: "/icons/buy.png",
    label: "Помощь в покупке",
    value: 3,
    name: "shop_order",
  },
];

export const weightOptions = [
  { label: "kg", value: "kg/sm" },
  { label: "lb", value: "lb/inc" },
];

export const measureOptions = [
  { label: "sm", value: "sm" },
  { label: "inch", value: "inch" },
];

export const pickups = [
  {
    id: 1,
    name: "Atlas Express",
    type: 1,
    logo: "/images/logo.png",
    link: "#",
    tariffs: [],
  },
  {
    id: 2,
    name: "FedEx",
    type: 2,
    logo: "/images/fedex.png",
    link: "#",
    tariffs: [
      { name: "PRIORITY_OVERNIGHT", type: "PRIORITY_OVERNIGHT" },
      { name: "FEDEX_2_DAY", type: "FEDEX_2_DAY" },
      { name: "FEDEX_EXPRESS_SAVER", type: "FEDEX_EXPRESS_SAVER" },
      { name: "FEDEX_GROUND", type: "FEDEX_GROUND" },
    ],
  },
  {
    id: 3,
    name: "Ups",
    type: 3,
    logo: "/images/ups.png",
    link: "#",
    tariffs: [
      { name: "Next Day Air", type: "01" },
      { name: "2nd Day Air", type: "02" },
      { name: "Ground", type: "03" },
    ],
  },
];

export const months = [
  {
    label: "January",
    value: "01",
    index: 0,
  },
  {
    label: "February",
    value: "02",
    index: 1,
  },
  {
    label: "March",
    value: "03",
    index: 2,
  },
  {
    label: "April",
    value: "04",
    index: 3,
  },
  {
    label: "May",
    value: "05",
    index: 4,
  },
  {
    label: "June",
    value: "06",
    index: 5,
  },
  {
    label: "July",
    value: "07",
    index: 6,
  },
  {
    label: "August",
    value: "08",
    index: 7,
  },
  {
    label: "September",
    value: "09",
    index: 8,
  },
  {
    label: "October",
    value: "10",
    index: 9,
  },
  {
    label: "November",
    value: "11",
    index: 10,
  },
  {
    label: "December",
    value: "12",
    index: 11,
  },
];

export const orderStatus = [
  "in_customer",
  "arrive_warehouse",
  "in_warehouse",
  "delivered",
];

export const reviewsBreakpoints = {
  1140: {
    slidesPerView: 2,
  },
  320: {
    spaceBetween: "10px",
    slidesPerView: 1.1,
  },
};

export const faqBreakpoints = {
  1200: {
    slidesPerView: 3.5,
  },
  768: {
    slidesPerView: 2.5,
  },
  320: {
    slidesPerView: 1.5,
  },
};

export const shopCategories = [
  { label: "All", value: "" },
  { label: "Leisure", value: "Leisure" },
  { label: "Sport", value: "Sport" },
  { label: "Home and garden", value: "Home and garden" },
  { label: "Online Mall", value: "Online Mall" },
  { label: "Health & Beauty", value: "Health & Beauty" },
  { label: "Jewelry and gifts", value: "Jewelry and gifts" },
  { label: "Office supplies", value: "Office supplies" },
  { label: "Products for animals", value: "Products for animals" },
  {
    label: "Automobiles and accessories",
    value: "Automobiles and accessories",
  },
  { label: "Electronics & Accessories", value: "Electronics & Accessories" },
  { label: "Baby clothes and toys", value: "Baby clothes and toys" },
  {
    label: "Clothes shoes and accessories",
    value: "Clothes shoes and accessories",
  },
  { label: "Other", value: "Other" },
];

export const mobileNavbar = [
  {
    label: "Главная",
    link: "/profile",
    icon: "/icons/home.svg",
  },
  {
    label: "Посылки",
    link: "/parcels/orders",
    icon: "/icons/box.svg",
  },
  {
    label: "Адреса",
    link: "/addresses",
    icon: "/icons/location.svg",
  },
  {
    label: "Баланс",
    link: "/balance",
    icon: "/icons/wallet.svg",
  },
  {
    label: "Настройки",
    link: "/settings",
    icon: "/icons/settings.svg",
  },
];

export const SERVICES = [
  { service: "hold_storage", price: 3, logo: "/icons/hold.svg" },
  { service: "dont_pack", price: 10, logo: "/icons/scissors.svg" },
  { service: "save_packing", price: 4, logo: "/icons/lock.svg" },
  { service: "take_shot_out", price: 3, logo: "/icons/primary-camera.svg" },
  { service: "get_back", price: 5, logo: "/icons/return.svg" },
  { service: "change_receiver", price: 3, logo: "/icons/transfer.svg" },
  { service: "check_order", price: 0, logo: "/icons/check.svg" },
  { service: "consolidation", price: 1, logo: "/icons/hold.svg" },
  { service: "repacking", price: 7, logo: "/icons/hold.svg" },
  { service: "deconsolidation", price: 0, logo: "/icons/hold.svg" },
];
