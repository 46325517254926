import React from "react";
import { Link } from "react-router-dom";
interface Props {
  height: string;
}

export default function Logo({ height }: Props) {
  return (
    <Link
      to="/"
      className="logo"
      style={{ height: height, width: "fit-content" }}
    >
      <img src="/images/logo.png" alt="logo" width="100%" height="100%" />
    </Link>
  );
}
