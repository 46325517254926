import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    uz: {
      translation: {
        Rates: "Tariflar",
        Shops: "Do'konlar",
        Services: "Xizmatlar",
      },
    },
    en: {
      translation: {
        Rates: "Rates",
        Shops: "Shops",
        Services: "Services",
      },
    },
    ru: {
      translation: {
        Rates: "Тарифы",
        Shops: "Магазины",
        Services: "Услуги",
        kg: "кг",
        lb: "лб",
        "kg/sm": "кг",
        "lb/inc": "лб",
        January: "Январь",
        February: "Февраль",
        March: "Март",
        April: "Апрель",
        May: "Май",
        June: "Июнь",
        July: "Июль",
        August: "Август",
        September: "Сентябрь",
        October: "Октябрь",
        November: "Ноябрь",
        December: "Декабрь",
        orderStatus: {
          in_customer: "Ожидаемые",
          in_warehouse: "Подтверждено",
          shipped: "Отправленные",
          arrive_warehouse: "На складе",
          delivered: "Доставленны",
        },
        shopOrderStatus: {
          process: "Ожидаемые",
          bought: "Купленные",
          verified: "Готов к отправке",
          accepted: "Одобренные",
        },
        trackingStatus: {
          pending: "В ожидании",
          accepted: "Одобренные",
        },
        "С start по end": "С {{start}} по {{end}}",
      },
    },
  },
  lng: "ru",
  interpolation: {
    escapeValue: false,
  },
});

export function setCurrentLang(lang = "ru") {
  i18n.changeLanguage(lang);
}

export default i18n;
