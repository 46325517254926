import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../Logo";
import Button from "../buttons";

export default function AboutFgfSection() {
  const { t } = useTranslation();

  return (
    <section className="about-fgf-section">
      <div className="container text-center">
        <Logo height="200px" />
        <h2 className="size-2 natural-8 text-bold">
          {t("О Atlas Express")}
        </h2>
        <h6 className="size-7 natural-6 lh-24 text-medium">
          {t(
            "За короткий срок в 6 лет мы преобразовались из небольшого стартапа, занимающегося доставкой товаров из 30 различных стран, в крупнейшего и надежного мейлфорвардера. Наша компания теперь обладает собственными инновационными логистическими решениями, удобными автоматизированными складами, оборудованными самыми современными системами управления. Мы гордимся нашей командой профессионалов, которая распределена по всему миру, гарантируя надежную и эффективную работу нашего предприятия."
          )}
        </h6>
        <Button className="m-auto natural-3" onClick={() => {}}>
          {t("Подробнее о том, как мы работаем")}
        </Button>
      </div>
    </section>
  );
}
